/* Estilo por defecto */
.comentario-label {
    margin-right: 8px;
    /* Ajusta el valor según necesites */
}



.footer-icon {
    color: #ffffff;
    /* Ícono en blanco */
    font-size: 1.5rem;

}






.footer-text {
    color: white;
    /* Texto en blanco */
    font-size: var(--font-size-md);
    /* Ajusta el tamaño del texto */
    text-transform: capitalize;
}




.img-galeria-observacion {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .img-galeria-observacion:hover {
    transform: scale(1.05);
  }


/* Para pantallas más grandes */
@media (min-width: 768px) {
    .img-galeria-observacion {
        max-height: 15rem !important;
        /* Altura en pantallas más grandes */
    }
}

/* Para pantallas de móviles */
@media (max-width: 767px) {
    .rounded-button{
        width: 100%;
    }
    .img-galeria-observacion {
        max-height: 8rem !important;
        /* Altura en móviles */
    }
}




/* Contenedor del modal (pantalla completa) */
.image-modal-container-gallery {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* Evita mostrar contenido fuera del contenedor */
    touch-action: none;
    /* Desactiva gestos táctiles predeterminados */
    user-select: none;
    /* Evita la selección de texto o imágenes */
    -webkit-overflow-scrolling: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* Botón para cerrar el modal */
.close-image-button-gallery {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;

    /* Hacemos que sea un círculo y definimos tamaño */
    width: 40px;
    height: 40px;
    --border-radius: 50%;
    /* círculo */

    /* Centramos el icono dentro del botón */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Variables para el IonButton */
    --background: #000;
    /* fondo negro */
    --background-hover: #333;
    /* fondo negro más claro al hover */
    --background-activated: #555;
    /* fondo negro aún más claro al touch/click */
    --background-focused: #555;

    --color: #fff;
    /* ícono blanco */
    --padding-start: 0;
    /* quita padding extra si deseas */
    --padding-end: 0;
}

.comentario-open-button-gallery {
    position: absolute;
    bottom: 20px;
    /* Ajusta según el espacio deseado */
    left: 50%;

    transform: translateX(-50%);
    z-index: 10;

    /* Hacemos que sea un círculo y definimos tamaño */
    width: 40px;
    height: 40px;
    --border-radius: 50%;
    /* círculo */

    /* Centramos el icono dentro del botón */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Variables para el IonButton */
    --background: #000;
    /* fondo negro */
    --background-hover: #333;
    /* fondo negro más claro al hover */
    --background-activated: #555;
    /* fondo negro aún más claro al touch/click */
    --background-focused: #555;

    --color: #fff;
    /* ícono blanco */
    --padding-start: 0;
    /* quita padding extra si deseas */
    --padding-end: 0;
}

/* Imagen mostrada en el modal */
.modal-img-gallery {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Contenedor para la imagen y el ícono */
.container-img-with-icon-gallery {
    position: relative;
    display: inline-block;
    /* Si quieres que se comporte como bloque a 100% del contenedor padre, cambia a 'display: block' */
}

.img-gallery-modal {

    --background: rgba(0, 0, 0, 0.8);
    /* negro con 80% de opacidad */
}
.rounded-button{
    --border-radius: 15px;
}