.ion-card-descripcion {
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-description-cards);
    --background: var(--color-background-cards);
    box-shadow: none;
    margin-inline: 0px;
    margin-top: 5px;
    margin-bottom: 5px;  
}

.ion-card-descripcion-img {
    font-size: 0!important;
    border-radius: 20px;
   
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cards);
    box-shadow: none;
    margin-inline: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    min-height: 200px; /* Altura mínima de la card */
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 0;
}
/*cards descripcion font size */
.ion-card-descripcion-content p {
    font-size: var(--font-size-md) !important;
    color: var(--color-font-dark-grey);
}

.ion-card-descripcion-content h4 {
    font-size: var(--font-size-lg) !important;
}

.ion-card-descripcion-content h4.card-detail-title {
    font-size: var(--font-size-xl) !important;
    /* Cambia el tamaño o cualquier otra propiedad */
}

/*cards escritorio font size */
.ion-card-escritorio-content p {
    font-size: var(--font-size-md) !important;
    color: var(--color-font-dark-grey);
}

.ion-card-escritorio-content h4 {
    font-size: var(--font-size-lg) !important;
}

.ion-card-escritorio-content h1 {
    font-size: var(--font-size-xl) !important;
}

.ion-card-escritorio-content h2 {
    font-size: 1.325rem !important;
}

/*cards list font size */
.ion-card-list-content p {
    font-size: var(--font-size-md) !important;
    color: var(--color-font-dark-grey);
}

.ion-card-list-content h4 {
    font-size: var(--font-size-lg) !important;
}

/*cards list item font size */
.ion-card-item-content p {
    font-size: var(--font-size-xs)!important;
    color: var(--color-font-dark-grey);
}

.ion-card-item-content h4 {
    font-size: var(--font-size-lg) !important;
}

.text-small {
    font-size: 0.812rem !important;
    color: var(--color-font-light-grey);
}

.ion-icon-menu {
    font-size: 24px;
    color: #ffffff;
    background-color: var(--menu-icon-background-color);
    border-radius: 50%;
    padding: 10px 10px;
}



.ion-card-menu {
    border-radius: 20px;
    --background: var(--color-background-cards);
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cards);
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.ion-card-menu:hover {
    --menu-icon-background-color: #880d73;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

}

/* .card-content-md{
    padding-top: 10px;
  } */
.ion-card-list {
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-border-cards);
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 20px;
    --background: var(--color-background-cards);
}

.ion-card-list:hover {

    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

}

.img-card-list {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 5px 5px;
    border-radius: 20px;
}

.img-card-box {
    min-height: 200px;
}

.txt-titulo-list-item {
    max-width: 100%;
    overflow: hidden;
    display: block;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.ion-margin-bottom {
    margin-bottom: 16px;
    /* Ajusta según necesites */
}

.create-form-label {
    display: block;
    /* Asegura que el label ocupe toda la línea */
    font-weight: 500;
    /* Aplica el peso de la fuente */
    font-size: 1.5em;
    /* Opcional: ajusta el tamaño */
    letter-spacing: 0.5px;
    /* Opcional: ajusta el color */
}

.ion-input-upload {
    --padding-top: 15px;
}

.icon-upload-button {
    font-size: 35px;
    color: #3891ff
}

.spn-upload-image {
    text-transform: capitalize;
    color: var(--color-font-dark-grey);
    font-weight: 500;
    padding-top: 5px;
    font-size: .9em;
}

.spn-upload-subtitle {
    text-transform: capitalize;
    color: var(--color-font-light-grey);
    font-weight: 300;
    padding-top: 15px;
    font-size: .8em;
}

.ion-upload-img-button {
    margin-top: 15px;
    height: 7rem;
    --border-radius: 5px;
    --background: var(--color-light-button);
    --box-shadow: none;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    border: 2px dashed #3880ff;
    border-radius: 5px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #9b9a9d;
    font-size: var(--font-size-md);
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.ion-upload-img-button:hover {
    --background: var(--color-light-button-hover);
    border-color: #7a797d;
    cursor: pointer;
}

.d-flex-items-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.required-asterisk {
    color: red;
    font-weight: bold;
    margin-left: 0.15rem;
    /* Separación entre el texto y el asterisco */
    font-size: var(--font-size-lg);
    /* Ajusta el tamaño del asterisco */
}

.btn-guardar {
    margin-top: 2rem;
    /* Mayor separación vertical */
    text-transform: capitalize;
    /* Texto en minúscula */
    font-size: 1.2rem;
    /* Ajusta el tamaño del texto si es necesario */
}

.create-form-label-helper {
    display: flex;
    flex-direction: column;
    /* Asegura que el <label> y <small> estén en columna */
    gap: 15px;
    /* Espaciado entre <label> y <small> */
}

.txt-label-helper {
    font-weight: 500;
    /* Aplica el peso de la fuente */
    font-size: 1.5em;
    /* Opcional: ajusta el tamaño */
    letter-spacing: 0.5px;
}

.helper-text {
    font-size: var(--font-size-xs);
    color: var(--color-medium-grey);
    /* Un color gris para que no distraiga */
}

.create-form-input {
    margin-top: 15px;
}

.image-preview-container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.image-preview {
    width: 100%;
    border-radius: 5px;
}

.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;

    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.552);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
}

.delete-icon:hover {
    transform: scale(1.2);
}

.select-image-custom-modal {
    --border-radius: 20px;
    --background: var(--color-light-contrast);
    /* Puedes personalizar el color de fondo */
    --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* Para un efecto más estilizado */
}

.select-image-custom-modal .modal-wrapper {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    /* Asegura que el contenido no se salga de los bordes redondeados */
}
.bg-light{
    --ion-background-color: var(--color-light-contrast)!important;
}
.list-card-toolbar {

    padding: 0 16px;
    min-height: 45px;
    position: relative;
    /* Relativo para ajustar elementos internos */
}

.card-ellipsis-btn {
    --color: var(--color-font-dark-grey);

    --padding-start: 10px;
    --padding-end: 10px;
    display: flex;
    /* Permite centrar el ícono dentro del botón */
    align-items: center;
    /* Centra verticalmente */
    justify-content: center;
    /* Centra horizontalmente */
    position: absolute;
    /* Para ajustarlo al borde */

    right: 5px;
    /* Ajusta el espacio al borde derecho */

    z-index: 10;
}

.card-ellipsis-btn ion-icon {
    font-size: 18px;
    /* Tamaño del ícono */
    margin: 0;
    /* Sin espacio adicional */
}

.font-dark-grey {
    color: var(--color-font-dark-grey);
}

.align-items-center {
    align-items: center;
}

.line-height-1 {
    line-height: 1;
}

.justify-content-between {
    justify-content: space-between;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-justify {
    text-align: justify;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}
.font-weight-300 {
    font-weight: 300 !important;
}
.font-weight-400 {
    font-weight: 400 !important;
}

.font-12 {
    font-size: 0.75rem !important;
    /* 12px / 16px */
}

.font-13 {
    font-size: 0.8125rem !important;
    /* 13px / 16px */
}

.font-14 {
    font-size: 0.875rem !important;
    /* 14px / 16px */
}

.font-16 {
    font-size: var(--font-size-md) !important;
    /* 16px / 16px */
}

.font-18 {
    font-size: var(--font-size-lg) !important;
    /* 18px / 16px */
}

.font-20 {
    font-size: var(--font-size-xl) !important;
    /* 20px / 16px */
}

.font-22 {
    font-size: 1.375rem !important;
    /* 22px / 16px */
}

.font-bold {
    font-weight: 600 !important;
    letter-spacing: 0.0125em;
}
.font-size-xl{
    font-size: var(--font-size-xl)!important;
}
.font-light-grey{
    color: var(--color-font-light-grey)!important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.d-inline-block {
    display: inline-block;
}

.m-0 {
    margin: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px !important;
}

.ml-2 {
    margin-left: 10px !important;
}

.ml-3 {
    margin-left: 15px !important;
}

.mb-2 {
    margin-bottom: 10px !important;
}

.mb-3 {
    margin-bottom: 15px !important;
}

.mb-4 {
    margin-bottom: 20px !important;
}

.mt-1 {
    margin-top: 5px !important;
}

.mt-2 {
    margin-top: 10px !important;
}

.mt-3 {
    margin-top: 15px !important;
}

.mt-4 {
    margin-top: 20px !important;
}

.mb-1 {
    margin-bottom: 5px !important;
}

.ml-auto {
    margin-left: auto;
}

.h-100 {
    min-height: 30px !important;
    height: 100%;
}


.ion-grid-grey {
    background-color: #edf0f5;
    width: 100%;
}

.no-indent {
    padding-left: 1rem;
    margin-left: 0;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.ion-content-grey {
    --background: var(--ion-background-color, var(--color-background-body));
}

.border-header {
    border-bottom: none;
}

.mr-1 {
    margin-right: 5px;
}

.d-flex {
    display: flex !important;
}

.d-inline-block {
    display: inline-block;
}

.flex-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* Espacio entre elementos si es necesario */
}

.center-content {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
}


.tab-button-det {
    --color-selected: var(--color-tab-selected);
    max-width: none !important;
}

.badge-background-det {
    --background: #10001d !important;
    overflow: hidden;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 3em;
}

.tab-selected {
    border-top: solid !important;
    border-top-width: .25em !important;
}

.dashboard-row {
    padding-inline-start: var(--ion-grid-column-padding-md, var(--ion-grid-column-padding, 10px));
    padding-inline-end: var(--ion-grid-column-padding-md, var(--ion-grid-column-padding, 10px));
}

.status-bar {
    background-color: #edf0f5 !important;
    /* Establecer el color de fondo de la barra de estado */
}

.status-bar-light {
    color: #edf0f5 !important;
    /* Establecer el color del texto en un tema claro */
}

.status-bar-dark {
    color: #edf0f5 !important;
    /* Establecer el color del texto en un tema oscuro */
}

.item-menu {
    --border-style: none;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .04em;
}

.icon-menu {
    margin-inline-end: 18px;
    --ionicon-stroke-width: 40px;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    color: #1d1d1d;
}

.menu-toolbar {
    
    height: 71px;
}

.ion-logo-splitPane {
    margin-left: 16px;
    display: inline-block;
    height: 31px;
    width: 117px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    background: url(../../public/assets/logo-obrabit-menu-5.svg);
    background-image: url(../../public/assets/logo-obrabit-menu-5.svg);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}

.services-toolbar {
    --padding-start: 20px;
    --padding-end: 20px;
}

.login-toolbar-btn {
    --color: var(--color-font-dark-grey);
    text-transform: capitalize;
}

.ion-logo-servicios {
    display: inline-block;
    height: 35px;
    width: 149px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    background: url(../../public/assets/logo-obrabit-toolbar.svg);
    background-image: url(../../public/assets/logo-obrabit-toolbar.svg);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}
.main-menu-item{
    color: var(--color-font-dark-grey);
}


@media (max-width: 768px) {

    /*cards descripcion font size */
    .ion-card-descripcion-content p {
        font-size: var(--font-size-md)!important;
        color: var(--color-font-medium-grey);
        
    }

    .ion-card-descripcion-content h4 {
        font-size: var(--font-size-lg) !important;
    }

    .ion-card-descripcion-content h4.card-detail-title {
        font-size: var(--font-size-xl) !important;
        /* Cambia el tamaño o cualquier otra propiedad */
    }

    /*cards escritorio font size */
    .ion-card-escritorio-content p {
        font-size: var(--font-size-md) !important;
        color: var(--color-font-medium-grey);
    }

    .ion-card-escritorio-content h4 {
        font-size: var(--font-size-lg) !important;
    }

    .ion-card-escritorio-content h1 {
        font-size: var(--font-size-xl) !important;
    }

    .ion-card-escritorio-content h2 {
        font-size: var(--font-size-xxl) !important;
    }

    /*cards list font size */
    .ion-card-list-content p {
        font-size: var(--font-size-md) !important;
        color: var(--color-font-medium-grey);
    }

    .ion-card-list-content h4 {
        font-size: var(--font-size-lg) !important;
    }

    /*cards list item font size */
    .ion-card-item-content p {
        font-size: var(--font-size-xs) !important;
        color: var(--color-font-dark-grey);
    }

    .ion-card-item-content h4 {
        font-size: var(--font-size-lg) !important;
    }

    .text-small {
        font-size: var(--font-size-xs) !important;
        color: var(--color-font-light-grey);
    }
}