.ion-icon-visita-item{
    font-size: 1.8rem;
    color: var(--color-icon-violet);
    background-color: rgba(var(--color-icon-violet-rgb), 0.15); 
    border-radius: 30%; 
    padding: 15px 15px;
    margin-left: 0;
    margin-right: 10px; 
}
.ion-icon-visita-forward{
    font-size: 1.5rem;
    color: var(--color-icon-violet);
    margin-left: auto;
}
.obra-item-box{
    width: 100%;
}  

.obra-item-text {
    display: flex;
    flex-direction: column; /* Alinea el título y subtítulo verticalmente */
  }