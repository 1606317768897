html {
    --ion-dynamic-font: var(--ion-default-dynamic-font);
    --ion-font-family: 'Roboto'; /* Reemplázalo por la fuente que desees */
}
body {
    background-color: var(--color-background-body);
    letter-spacing: 0.015em;
}
p {
    line-height: 1.6; /* Ajusta este valor según la legibilidad que necesites */
}

.hidden-tab-bar {
    display: none;
}
