  .escritorio-item-card-content {
    min-height: 100% !important;
  }
  .ion-icon-menu-gestion {
    font-size: 24px;
    color: #ffffff;
    background-color: var(--color-icon-pink);
    border-radius: 50%;
    padding: 10px 10px;
}
  .descripcion-limite {
    min-height: calc(1.25em * 4);
   
    /* Asegura consistencia en las líneas */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }